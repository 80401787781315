<template>
  <transition name="dialog-unroll">
    <div v-if="displaySync" class="dialog-overlay">
      <div
        class="dialog-container"
        :style="{
          height: containerHeight,
          width: containerWidth,
          maxHeight: containerMaxHeight,
          maxWidth: containerMaxWidth,
          minHeight: containerMinHeight,
          minWidth: containerMinWidth,
        }"
        :prompt="warningmsg"
      >
        <div class="dialog-header" :style="headerStyle" v-if="isShowHeader">
          <h2 class="dialog-label" :style="labelStyle">{{ title }}</h2>
          <button
            class="close-button"
            v-if="showClose"
            @click.prevent="closeDialog"
            :style="labelStyle"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="dialog-content">
          <div class="scrollable-content">
            <button
              class="close-button"
              style="float: right"
              v-if="showClose && !isShowHeader"
              @click.prevent="closeDialog"
              :style="labelStyle"
            >
              <i class="fa fa-times"></i>
            </button>
            <slot></slot>
          </div>
        </div>
        <div class="dialog-footer" v-if="isShowFooter">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionsHelper from '@/mixins/permissionsHelper';
export default {
  data() {
    return {
      companyInfo: {},
      displaySync: false,
      warningmsg: 'Are you sure to cancel the transaction?',
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Dialog',
    },
    containerHeight: {
      type: String, // You can use Number or String based on your requirement
      default: 'auto', // Default height can be set as per your requirement
    },
    containerWidth: {
      type: String, // You can use Number or String based on your requirement
      default: 'auto', // Default width can be set as per your requirement
    },
    containerMinHeight: {
      type: String,
      default: 'auto',
    },
    containerMinWidth: {
      type: String,
      default: 'auto',
    },
    containerMaxHeight: {
      type: String,
      default: 'auto',
    },
    containerMaxWidth: {
      type: String,
      default: 'auto',
    },
    isBtnShow: {
      type: Boolean,
      default: true,
    },
    disableTopBar: {
      type: Boolean,
      default: false,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    showClose: {
      // Show and hide the close of dialog Based on requirement
      type: Boolean,
      default: true,
    },
    beforeConfirm: Function,
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    isShowFooter: {
      // Show and hide the footer of dialog Based on requirement
      type: Boolean,
      default: true,
    },
    isShowHeader: {
      type: Boolean,
      default: true,
    },
    cancelConfirmation: {
      type: Boolean,
      default: false,
    },
    emitClose: {
      type: Boolean,
      default: false,
    },
    prompt: {
      type: String,
    },
  },
  mixins: [PermissionsHelper],
  beforeMount() {
    this.displaySync = this.visible;
    this.getCompanyInfo();
  },
  // mounted(){
  //   this.getCompanyInfo();
  // },
  computed: {
    ...mapGetters('auth', ['getActiveWorkspace']),
    ...mapGetters('company', [
      'getContactLoginSlug',
      'getUpdateCompany',
      'getCompanyDetails',
    ]),
    ...mapGetters('company', ['getContactLoginSlug']),
    headerStyle() {
      if (this.disableTopBar == true) {
        return {
          backgroundColor: '#ffffff',
          color: 'slategray',
        };
      }
      return {
        backgroundColor: this.companyInfo.dialog_header_color || '#eee', // Default to #eee if menubackground is undefined
        // You can add other styles here if needed
      };
    },
    labelStyle() {
      if (this.disableTopBar == true) {
        return {
          color: 'slategray',
        };
      }
      return {
        color: this.companyInfo.dialog_header_text_color || '#808080',
      };
    },
  },
  methods: {
    closeDialog() {
      if (this.cancelConfirmation) {
        this.$confirm(this.prompt, 'Warning', {
          confirmButtonText: this.confirmText,
          cancelButtonText: this.cancelText,
        })
          .then(async () => {
            this.$emit('before-close');
            this.displaySync = false;
          })
          .catch(async (err) => {
            console.log(err);
            this.$emit('before-close-cancel');
          });
      } else {
        this.$emit('before-close');
        if (!this.emitClose) {
          this.displaySync = false;
        }
      }
    },
    async getCompanyInfo() {
      // this.loading = true;
      try {
        if (this.getCompanyDetails) {
          this.companyInfo = this.getCompanyDetails;
        } else {
          this.companyInfo = { ...this.getContactLoginSlug };
        }
        // let company_id;
        // if (this.getActiveWorkspace?.company_id) {
        //   company_id = this.getActiveWorkspace.company_id;
        // } else if (this.getAuthenticatedUser?.company_id) {
        //   company_id = this.getAuthenticatedUser.company_id;
        // }
        // if (company_id) {
        //   if (this.getCompanyDetails?._id == company_id) {
        //     this.companyInfo = this.getCompanyDetails;
        //   } else {
        //     await this.$store.dispatch("company/fetchCompany", company_id);
        //     if (this.getCompanyDetails) {
        //       this.companyInfo = this.getCompanyDetails;
        //     }
        //   }
        // }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: 'Error',
          message: e,
        });
      }
    },
  },
  watch: {
    visible(newValue) {
      if (this.displaySync != newValue) {
        this.displaySync = newValue;
      }
    },
  },
};
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px) !important;
}

.dialog-container {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0vw;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 1;
}

.dialog-label {
  margin: 0;
}

.close-button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
  color: #555;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
}

.scrollable-content {
  overflow-y: auto;
  padding: 0px 20px 0px 30px;
  overflow-x: hidden;
}

.dialog-footer {
  padding: 10px 20px;
  background-color: #eee;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: end;
}
@media screen and (max-width: 1006px) {
  /* Add your custom styles here */
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px) !important;
  }

  .dialog-container {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin: 0px;
    max-width: calc(100%);
    max-height: 760px;
    display: flex;
    flex-direction: column;
  }

  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .dialog-label {
    margin: 0;
  }

  .close-button {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 20px;
    color: #555;
  }

  .dialog-content {
    flex-grow: 1;
    overflow-y: auto;
  }

  .scrollable-content {
    overflow-y: auto;
    padding: 0px 0px 0px 0px;
    overflow-x: hidden;
  }

  .dialog-footer {
    padding: 10px 20px;
    background: #eee;
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: end;
  }
}
</style>
